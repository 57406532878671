import Vue from 'vue'
import VueRouter from 'vue-router'
import localconfig from '../src/global_settings.json';

const DefaultIndex = () => import('./components/home/DefaultIndex')
const Profile = () => import('./components/user/Profile')
const Signup = () => import('./components/user/Signup')
const Notification = () => import('./components/user/Notification')
const Wallet = () => import('./components/wallet/Wallet')
const AdvanceSearch = () => import('./components/search/AdvanceSearch')
const Shopdetail = () => import('./components/shop/Shopdetail')
const ProductDetail = () => import('./components/product/ProductDetail')
const ShopFullDetail = () => import('./components/shop/ShopFullDetail')
const Cart = () => import('./components/checkout/Cart')
const PlaceOrder = () => import('./components/checkout/PlaceOrder')
const OrderPlaced = () => import('./components/order/OrderPlaced')
const OrderCancelled = () => import('./components/order/OrderCancelled')
// const Category = () => import('./components/category/Category')
// const SearchCategory = () => import('./components/search/SearchCategory')
const AllBestSeller = () => import('./components/home/bestseller/AllBestSeller')
const Chat = () => import('./components/chat/Chat')
const PaymentInfo = () => import('./components/checkout/PaymentInfo')
const AddWishlist = () => import('./components/wishlist/Addwishlist')
const Editwishlist = () => import('./components/wishlist/Editwishlist')
const AboutUs = () => import('./components/pages/AboutUs')
const TermsOfUse = () => import('./components/pages/TermsOfUse')
const AdvertiseWithUs = () => import('./components/pages/AdvertiseWithUs')
const Faq = () => import('./components/pages/Faq')
const PrivacyPolicy = () => import('./components/pages/PrivacyPolicy.vue')
const Newsletter = () => import('./components/pages/Newsletter')
const StudentDiscountProgram = () => import('./components/pages/StudentDiscountProgram')
const Location = () => import('./components/pages/Location')
const Feedback = () => import('./components/pages/Feedback')
const ItemCollectionPage = () => import('./components/pages/ItemCollectionPage')

Vue.use(VueRouter)
const routes = [
    {
        path:"/",
        name:"home",
        component:DefaultIndex,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/paymentinfo",
        name:"paymentinfo",
        component:PaymentInfo,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/profile",
        name:"profile",
        component:Profile,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/signup",
        name:"signup",
        component:Signup,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/notification",
        name:"notification",
        component:Notification,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/wallet",
        name:"wallet",
        component:Wallet,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/search",
        name:"search",
        component:AdvanceSearch,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/shopdetail",
        name:"shopdetail",
        component:Shopdetail,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/productdetail",
        name:"productdetail",
        component:ProductDetail,
    },
    {
        path:"/shopfulldetail",
        name:"shopfulldetail",
        component:ShopFullDetail,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon"),
        }
    },    
    {
        path:"/cart",
        name:"cart",
        component:Cart,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/placeorder",
        name:"placeorder",
        component:PlaceOrder,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/orderplaced",
        name:"orderplaced",
        component:OrderPlaced,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    {
        path:"/ordercancelled",
        name:"ordercancelled",
        component:OrderCancelled,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
        
    },
    // {
    //     path:"/category",
    //     name:"category",
    //     component:Category,
    //     meta: {
    //         title : localStorage.getItem("title"),
    //         icon : localStorage.getItem("favicon")
    //     }
        
    // },
    // {
    //     path:"/search-category",
    //     name:"searchcategory",
    //     component:SearchCategory,
    //     meta: {
    //         title : localStorage.getItem("title"),
    //         icon : localStorage.getItem("favicon")
    //     }
        
    // },
    {
        path:"/best-sellers",
        name:"all-best-seller",
        component:AllBestSeller,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/chat",
        name:"chat",
        props: true,
        component:Chat,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/add-wishlist",
        name:"add-wishlist",
        component:AddWishlist,
        meta:{
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/edit-wishlist",
        name:"edit-wishlist",
        component:Editwishlist,
        meta:{
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/about-us",
        name:"about-us",
        component:AboutUs,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/tou",
        name:"tou",
        component:TermsOfUse,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/return-policy",
        name:"return-policy",
        component:AdvertiseWithUs,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/faq",
        name:"faq",
        component:Faq,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/privacy-policy",
        name:"PrivacyPolicy",
        component:PrivacyPolicy,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/newsletter",
        name:"newsletter",
        component:Newsletter,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/UniversityProgram",
        name:"StudentDiscountProgram",
        component:StudentDiscountProgram,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/return-center",
        name:"return-center",
        component:Location,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    {
        path:"/feedback",
        name:"feedback",
        component:Feedback,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    },
    // {
    //     path:"/item-collection/:type/:categoryid",
    //     name:"item-collection-type-categoryid",
    //     component:ItemCollectionPage,
    //     meta: {
    //         title : localStorage.getItem("title"),
    //         icon : localStorage.getItem("favicon")
    //     }
    // },
    {
        path:"/item-collection",
        name:"item-collection-type-categoryid",
        component:ItemCollectionPage,
        meta: {
            title : localStorage.getItem("title"),
            icon : localStorage.getItem("favicon")
        }
    }
]

const router = new VueRouter({
    mode:"history",
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((toRoute,fromRoute,next) => {
    if (localStorage.getItem("user") == null || localStorage.getItem("user") == undefined)
    {
        if (toRoute.name != 'newsletter' && toRoute.name != 'PrivacyPolicy' && toRoute.name != 'about-us' && toRoute.name != 'StudentDiscountProgram' && toRoute.name != 'faq' && toRoute.name!='item-collection-type-categoryid' && toRoute.name != 'tou' && toRoute.name != 'return-policy' && toRoute.name != 'return-center' && toRoute.name != 'feedback' && toRoute.name != 'searchcategory' && toRoute.name != 'all-best-seller' && toRoute.name != 'shopdetail' && toRoute.name != 'category' && toRoute.name != 'home' && toRoute.name != 'productdetail' && toRoute.name != 'signup'  && toRoute.name != 'shopfulldetail'  && (toRoute.name != 'search') || toRoute.hash == "#favorite-store")
        {
           window.location.href = "/";
        }
    }
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : localconfig.platform_page_title; 
    window.document.querySelector("link[rel~='icon']").href = toRoute.meta.icon;
    next();
})

router.afterEach((to,from) => {
    if(to.name!=null && to.name.toLowerCase()!='shopdetail' && to.name.toLowerCase()!='search')
    {
      localStorage.removeItem("home-search");
      localStorage.removeItem("searchqry");
    }
    else if(from.name == null)
    {
        //localStorage.removeItem("home-search");
    }

})
export default router;
