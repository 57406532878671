<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	name: 'app',
}
</script>
<style>
	@import "./assets/css/bootstrap.min.css";
	@import "./assets/css/owl.carousel.min.css";
	@import "./assets/css/style.css";
	.owl-carousel .owl-item img {
		display: block !important;
		width: auto !important;
		margin: 0 auto;
	}
	.owl-carousel .owl-nav div.owl-next, .owl-carousel .owl-nav div.owl-prev, .owl-carousel div.owl-dot {
		background: 0 0 !important;
		color: inherit !important;
		border: none !important;
		padding: 0!important;
		font: inherit !important;
	}
</style>
